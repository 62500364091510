import React, { useState, useEffect } from 'react';
import { Table, Form, Button, Modal } from 'react-bootstrap';
import axiosInstance from '../utils/axiosInstance';

function Categories() {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState({ name: '', field_type: 'text', ebook_type: '' });
  const [showModal, setShowModal] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);
  const [ebookTypes, setEbookTypes] = useState([]);
  const [selectedEbookType, setSelectedEbookType] = useState('');

  useEffect(() => {
    fetchCategories();
    fetchEbookTypes();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axiosInstance.get('/custom-fields');
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchEbookTypes = async () => {
    try {
      const response = await axiosInstance.get('/ebook-types');
      setEbookTypes(response.data);
    } catch (error) {
      console.error('Error fetching ebook types:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingCategory) {
        await axiosInstance.put(`/custom-fields/${editingCategory.id}`, newCategory);
      } else {
        await axiosInstance.post('/custom-fields', newCategory);
      }
      fetchCategories();
      setNewCategory({ name: '', field_type: 'text', ebook_type: '' });
      setShowModal(false);
      setEditingCategory(null);
    } catch (error) {
      console.error('Error saving category:', error);
    }
  };

  const handleEdit = (category) => {
    setEditingCategory(category);
    setNewCategory({ name: category.name, field_type: category.field_type, ebook_type: category.ebook_type });
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/custom-fields/${id}`);
      fetchCategories();
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

  const handleEbookTypeChange = (e) => {
    setSelectedEbookType(e.target.value);
  };

  return (
    <div>
      <h2>Custom Fields</h2>
      <Form.Group>
        <Form.Label>Select Ebook Type</Form.Label>
        <Form.Control
          as="select"
          value={selectedEbookType}
          onChange={handleEbookTypeChange}
        >
          <option value="">Select Ebook Type</option>
          {ebookTypes.map((type) => (
            <option key={type.id} value={type.id}>{type.name}</option>
          ))}
        </Form.Control>
      </Form.Group>
      <Button variant="primary" onClick={() => setShowModal(true)} className="mb-3">
        Add New Custom Field
      </Button>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Field Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories
            .filter(category => category.ebook_type === selectedEbookType)
            .map((category) => (
              <tr key={category.id}>
                <td>{category.name}</td>
                <td>{category.field_type}</td>
                <td>
                  <Button variant="info" size="sm" onClick={() => handleEdit(category)} className="mr-2">
                    Edit
                  </Button>
                  <Button variant="danger" size="sm" onClick={() => handleDelete(category.id)}>
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => {
        setShowModal(false);
        setEditingCategory(null);
        setNewCategory({ name: '', field_type: 'text', ebook_type: '' });
      }}>
        <Modal.Header closeButton>
          <Modal.Title>{editingCategory ? 'Edit Custom Field' : 'Add New Custom Field'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Ebook Type</Form.Label>
              <Form.Control
                as="select"
                value={newCategory.ebook_type}
                onChange={(e) => setNewCategory({ ...newCategory, ebook_type: e.target.value })}
                required
              >
                <option value="">Select Ebook Type</option>
                {ebookTypes.map((type) => (
                  <option key={type.id} value={type.id}>{type.name}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter field name"
                value={newCategory.name}
                onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Field Type</Form.Label>
              <Form.Control
                as="select"
                value={newCategory.field_type}
                onChange={(e) => setNewCategory({ ...newCategory, field_type: e.target.value })}
              >
                <option value="text">Text</option>
                <option value="number">Number</option>
                <option value="date">Date</option>
                <option value="boolean">Boolean</option>
              </Form.Control>
            </Form.Group>
            <Button variant="primary" type="submit">
              {editingCategory ? 'Update' : 'Add'} Custom Field
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Categories;