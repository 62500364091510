import React, { useState, useEffect } from 'react';
import { Table, Form, Button, Modal } from 'react-bootstrap';
import axiosInstance from '../utils/axiosInstance';

function Authors() {
  const [authors, setAuthors] = useState([]);
  const [newAuthor, setNewAuthor] = useState({ name: '' });
  const [showModal, setShowModal] = useState(false);
  const [editingAuthor, setEditingAuthor] = useState(null);

  useEffect(() => {
    fetchAuthors();
  }, []);

  const fetchAuthors = async () => {
    try {
      const response = await axiosInstance.get('/authors');
      setAuthors(response.data);
    } catch (error) {
      console.error('Error fetching authors:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingAuthor) {
        await axiosInstance.put(`/authors/${editingAuthor.id}`, newAuthor);
      } else {
        await axiosInstance.post('/authors', newAuthor);
      }
      fetchAuthors();
      setNewAuthor({ name: '' });
      setShowModal(false);
      setEditingAuthor(null);
    } catch (error) {
      console.error('Error saving author:', error);
    }
  };

  const handleEdit = (author) => {
    setEditingAuthor(author);
    setNewAuthor({ name: author.name });
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/authors/${id}`);
      fetchAuthors();
    } catch (error) {
      console.error('Error deleting author:', error);
    }
  };

  return (
    <div>
      <h2>Authors</h2>
      <Button variant="primary" onClick={() => setShowModal(true)} className="mb-3">
        Add New Author
      </Button>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {authors.map((author) => (
            <tr key={author.id}>
              <td>{author.name}</td>
              <td>
                <Button variant="info" size="sm" onClick={() => handleEdit(author)} className="mr-2">
                  Edit
                </Button>
                <Button variant="danger" size="sm" onClick={() => handleDelete(author.id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => {
        setShowModal(false);
        setEditingAuthor(null);
        setNewAuthor({ name: '' });
      }}>
        <Modal.Header closeButton>
          <Modal.Title>{editingAuthor ? 'Edit Author' : 'Add New Author'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter author name"
                value={newAuthor.name}
                onChange={(e) => setNewAuthor({ ...newAuthor, name: e.target.value })}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              {editingAuthor ? 'Update' : 'Add'} Author
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Authors;