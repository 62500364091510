import React, { useState, useEffect } from 'react';
import { Table, Form, Button, Modal } from 'react-bootstrap';
import axiosInstance from '../utils/axiosInstance';

function EbookTypes() {
  const [ebookTypes, setEbookTypes] = useState([]);
  const [newEbookType, setNewEbookType] = useState({ name: '' });
  const [showModal, setShowModal] = useState(false);
  const [editingEbookType, setEditingEbookType] = useState(null);

  useEffect(() => {
    fetchEbookTypes();
  }, []);

  const fetchEbookTypes = async () => {
    try {
      const response = await axiosInstance.get('/ebook-types');
      setEbookTypes(response.data);
    } catch (error) {
      console.error('Error fetching ebook types:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingEbookType) {
        await axiosInstance.put(`/ebook-types/${editingEbookType.id}`, newEbookType);
      } else {
        await axiosInstance.post('/ebook-types', newEbookType);
      }
      fetchEbookTypes();
      setNewEbookType({ name: '' });
      setShowModal(false);
      setEditingEbookType(null);
    } catch (error) {
      console.error('Error saving ebook type:', error);
    }
  };

  const handleEdit = (ebookType) => {
    setEditingEbookType(ebookType);
    setNewEbookType({ name: ebookType.name });
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/ebook-types/${id}`);
      fetchEbookTypes();
    } catch (error) {
      console.error('Error deleting ebook type:', error);
    }
  };

  return (
    <div>
      <h2>Ebook Types</h2>
      <Button variant="primary" onClick={() => setShowModal(true)} className="mb-3">
        Add New Ebook Type
      </Button>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {ebookTypes.map((ebookType) => (
            <tr key={ebookType.id}>
              <td>{ebookType.name}</td>
              <td>
                <Button variant="info" size="sm" onClick={() => handleEdit(ebookType)} className="mr-2">
                  Edit
                </Button>
                <Button variant="danger" size="sm" onClick={() => handleDelete(ebookType.id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => {
        setShowModal(false);
        setEditingEbookType(null);
        setNewEbookType({ name: '' });
      }}>
        <Modal.Header closeButton>
          <Modal.Title>{editingEbookType ? 'Edit Ebook Type' : 'Add New Ebook Type'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter ebook type name"
                value={newEbookType.name}
                onChange={(e) => setNewEbookType({ ...newEbookType, name: e.target.value })}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              {editingEbookType ? 'Update' : 'Add'} Ebook Type
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default EbookTypes;