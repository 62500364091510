import React, { useState, useEffect } from 'react';
import { Table, Form, Button, Row, Col } from 'react-bootstrap';
import axiosInstance from '../utils/axiosInstance';
import './EbookManagement.css'; // Import file CSS

function EbookManagement() {
  const [ebooks, setEbooks] = useState([]);
  const [filteredEbooks, setFilteredEbooks] = useState([]);
  const [ebookTypes, setEbookTypes] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [selectedEbook, setSelectedEbook] = useState(null);
  const [customFields, setCustomFields] = useState([]);
  const [filter, setFilter] = useState({ type: '', author: '', search: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchEbookTypes();
    fetchAuthors();
    fetchEbooks();
  }, [currentPage, filter.type, filter.author]);

  useEffect(() => {
    filterEbooks();
  }, [ebooks, filter.search]);

  const fetchEbookTypes = async () => {
    try {
      const response = await axiosInstance.get('/ebook-types');
      setEbookTypes(response.data);
    } catch (error) {
      console.error('Error fetching ebook types:', error);
    }
  };

  const fetchAuthors = async () => {
    try {
      const response = await axiosInstance.get('/authors');
      setAuthors(response.data);
    } catch (error) {
      console.error('Error fetching authors:', error);
    }
  };

  const fetchEbooks = async () => {
    try {
      const response = await axiosInstance.get('/ebooks', {
        params: { type: filter.type, author: filter.author, page: currentPage }
      });
      setEbooks(response.data.ebooks);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching ebooks:', error);
    }
  };

  const fetchCustomFields = async (ebookId) => {
    try {
      const response = await axiosInstance.get(`/ebooks/${ebookId}/custom-fields`);
      return response.data;
    } catch (error) {
      console.error('Error fetching custom fields:', error);
      return [];
    }
  };

  const handleEbookSelect = async (ebook) => {
    setSelectedEbook(ebook);
    const fields = await fetchCustomFields(ebook.id);
    setCustomFields(fields);
  };

  const handleEbookUpdate = async (e) => {
    e.preventDefault();
    try {
      const customFieldValues = customFields.map((field) => ({
        id: field.id,
        value: field.value || ''
      }));

      await axiosInstance.put(`/ebooks/${selectedEbook.id}`, {
        title: selectedEbook.title,
        type_id: selectedEbook.type_id,
        author_id: selectedEbook.author_id,
        customFields: customFieldValues
      });
      fetchEbooks();
      setSelectedEbook(null);
    } catch (error) {
      console.error('Error updating ebook:', error);
    }
  };

  const handleEbookDelete = async (ebookId) => {
    try {
      await axiosInstance.delete(`/ebooks/${ebookId}`);
      fetchEbooks();
    } catch (error) {
      console.error('Error deleting ebook:', error);
    }
  };

  const handleFilterChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
    setCurrentPage(1);
  };

  const handleCustomFieldChange = (fieldId, value) => {
    const updatedFields = customFields.map((field) =>
      field.id === fieldId ? { ...field, value } : field
    );
    setCustomFields(updatedFields);
  };

  const filterEbooks = () => {
    const filtered = ebooks.filter(ebook =>
      ebook.title.toLowerCase().includes(filter.search.toLowerCase())
    );
    setFilteredEbooks(filtered);
  };

  const autoResizeTextarea = (e) => {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  return (
    <div>
      <h2>Ebook Management</h2>
      <Row className="mb-3">
        <Col>
          <Form.Group>
            <Form.Label>Filter by Type</Form.Label>
            <Form.Control as="select" name="type" onChange={handleFilterChange} value={filter.type}>
              <option value="">All Types</option>
              {ebookTypes.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Filter by Author</Form.Label>
            <Form.Control as="select" name="author" onChange={handleFilterChange} value={filter.author}>
              <option value="">All Authors</option>
              {authors.map((author) => (
                <option key={author.id} value={author.id}>
                  {author.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Search</Form.Label>
            <Form.Control
              type="text"
              name="search"
              placeholder="Search by title"
              onChange={handleFilterChange}
              value={filter.search}
            />
          </Form.Group>
        </Col>
      </Row>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Title</th>
            <th>Type</th>
            <th>Author</th>
            <th>Storage Location</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredEbooks.map((ebook) => (
            <tr key={ebook.id}>
              <td>{ebook.title}</td>
              <td>{ebook.type_name}</td>
              <td>{ebook.author_name}</td>
              <td>{ebook.storage_location}</td>
              <td>
                <Button variant="info" size="sm" onClick={() => handleEbookSelect(ebook)}>
                  View/Edit
                </Button>
                <Button variant="danger" size="sm" onClick={() => handleEbookDelete(ebook.id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-center">
        <Button
          variant="outline-primary"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <span className="mx-2">
          Page {currentPage} of {totalPages}
        </span>
        <Button
          variant="outline-primary"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>
      {selectedEbook && (
        <div className="mt-4">
          <h3>Edit Ebook</h3>
          <Form onSubmit={handleEbookUpdate}>
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={selectedEbook.title}
                onChange={(e) => setSelectedEbook({ ...selectedEbook, title: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Type</Form.Label>
              <Form.Control
                type="text"
                value={ebookTypes.find(type => type.id === selectedEbook.type_id)?.name || ''}
                readOnly
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Author</Form.Label>
              <Form.Control
                as="select"
                value={selectedEbook.author_id}
                onChange={(e) => setSelectedEbook({ ...selectedEbook, author_id: e.target.value })}
              >
                {authors.map((author) => (
                  <option key={author.id} value={author.id}>
                    {author.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {customFields.map((field) => (
              <Form.Group key={field.id} className="custom-field">
                <Form.Label className="custom-field-label">{field.name}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={1}
                  value={field.value || ''}
                  onChange={(e) => handleCustomFieldChange(field.id, e.target.value)}
                  onInput={autoResizeTextarea}
                  className="custom-field-input"
                />
              </Form.Group>
            ))}
            <Button variant="primary" type="submit">
              Update Ebook
            </Button>
          </Form>
        </div>
      )}
    </div>
  );
}

export default EbookManagement;