import React, { useState } from 'react';
import { Container, Row, Col, Nav, Button } from 'react-bootstrap';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import Categories from './Categories';
import Authors from './Authors';
import EbookTypes from './EbookTypes';
import EbookUpload from './EbookUpload';
import EbookManagement from './EbookManagement';
import './Dashboard.css';

function Dashboard({ onLogout }) {
  const [activeComponent, setActiveComponent] = useState('categories');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [previousComponent, setPreviousComponent] = useState(null);

  const handleNavClick = (component) => {
    setPreviousComponent(activeComponent);
    setActiveComponent(component);
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case 'categories':
        return <Categories />;
      case 'authors':
        return <Authors />;
      case 'ebookTypes':
        return <EbookTypes />;
      case 'ebookUpload':
        return <EbookUpload />;
      case 'ebookManagement':
        return <EbookManagement />;
      default:
        return <Categories />;
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Container fluid>
      <Row>
        <Col className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
          <Button variant="link" className="sidebar-toggle" onClick={toggleSidebar}>
            {isSidebarOpen ? <FaArrowLeft className="toggle-icon" /> : <FaArrowRight className="toggle-icon" />}
          </Button>
          {isSidebarOpen && (
            <>
              <Nav className="flex-column mt-5">
                <Nav.Link
                  className={`nav-item ${activeComponent === 'categories' ? 'active' : ''} ${previousComponent === 'categories' ? 'previous' : ''}`}
                  onClick={() => handleNavClick('categories')}
                >
                  Categories
                </Nav.Link>
                <Nav.Link
                  className={`nav-item ${activeComponent === 'authors' ? 'active' : ''} ${previousComponent === 'authors' ? 'previous' : ''}`}
                  onClick={() => handleNavClick('authors')}
                >
                  Authors
                </Nav.Link>
                <Nav.Link
                  className={`nav-item ${activeComponent === 'ebookTypes' ? 'active' : ''} ${previousComponent === 'ebookTypes' ? 'previous' : ''}`}
                  onClick={() => handleNavClick('ebookTypes')}
                >
                  Ebook Types
                </Nav.Link>
                <Nav.Link
                  className={`nav-item ${activeComponent === 'ebookUpload' ? 'active' : ''} ${previousComponent === 'ebookUpload' ? 'previous' : ''}`}
                  onClick={() => handleNavClick('ebookUpload')}
                >
                  Upload Ebook
                </Nav.Link>
                <Nav.Link
                  className={`nav-item ${activeComponent === 'ebookManagement' ? 'active' : ''} ${previousComponent === 'ebookManagement' ? 'previous' : ''}`}
                  onClick={() => handleNavClick('ebookManagement')}
                >
                  Manage Ebooks
                </Nav.Link>
              </Nav>
              <Button variant="outline-danger" onClick={onLogout} className="logout-btn mt-3">
                Logout
              </Button>
            </>
          )}
        </Col>
        <Col className="content">
          <h1 className="mt-4 mb-4">Ebook Admin Dashboard</h1>
          {renderComponent()}
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;