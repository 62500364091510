import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Modal, ListGroup } from 'react-bootstrap';
import axiosInstance from '../utils/axiosInstance';
import './EbookUpload.css'; // Import file CSS

function EbookUpload() {
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState('');
  const [ebookType, setEbookType] = useState('');
  const [author, setAuthor] = useState('');
  const [storageLocation, setStorageLocation] = useState('');
  const [ebookTypes, setEbookTypes] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [customFieldValues, setCustomFieldValues] = useState({});
  const [message, setMessage] = useState('');
  const [directoryTree, setDirectoryTree] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newDirectoryName, setNewDirectoryName] = useState('');
  const [currentDirectory, setCurrentDirectory] = useState('');

  useEffect(() => {
    fetchEbookTypes();
    fetchAuthors();
    fetchDirectoryTree();
  }, []);

  useEffect(() => {
    if (ebookType) {
      fetchCustomFields(ebookType);
    }
  }, [ebookType]);

  const fetchEbookTypes = async () => {
    try {
      const response = await axiosInstance.get('/ebook-types');
      setEbookTypes(response.data);
    } catch (error) {
      console.error('Error fetching ebook types:', error);
    }
  };

  const fetchAuthors = async () => {
    try {
      const response = await axiosInstance.get('/authors');
      setAuthors(response.data);
    } catch (error) {
      console.error('Error fetching authors:', error);
    }
  };

  const fetchCustomFields = async (ebookTypeId) => {
    try {
      const response = await axiosInstance.get(`/custom-fields/by-ebook-type/${ebookTypeId}`);
      setCustomFields(response.data);
      setCustomFieldValues(response.data.reduce((acc, field) => {
        acc[field.name] = '';
        return acc;
      }, {}));
    } catch (error) {
      console.error('Error fetching custom fields:', error);
    }
  };

  const fetchDirectoryTree = async (dirPath = '') => {
    try {
      const response = await axiosInstance.get('/directories/tree', {
        params: { dirPath }
      });
      setDirectoryTree(response.data);
      setCurrentDirectory(dirPath);
    } catch (error) {
      console.error('Error fetching directory tree:', error);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    if (selectedFile && !title) {
      setTitle(selectedFile.name.replace('.pdf', ''));
    }
  };

  const handleCustomFieldChange = (e, fieldName) => {
    setCustomFieldValues({
      ...customFieldValues,
      [fieldName]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setMessage('Please select a file to upload.');
      return;
    }

    // Kiểm tra xem tên file đã tồn tại hay chưa
    try {
      const response = await axiosInstance.get('/ebooks/check-file-exists', {
        params: { fileName: file.name }
      });

      if (response.data.exists) {
        setMessage('File name already exists. Please find and delete the existing file before uploading.');
        return;
      }
    } catch (error) {
      console.error('Error checking file existence:', error);
      setMessage('Error checking file existence. Please try again.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('title', title);
    formData.append('ebookType', ebookType);
    formData.append('author', author);
    formData.append('storageLocation', storageLocation);
    formData.append('customFields', JSON.stringify(customFieldValues));

    try {
      await axiosInstance.post('/ebooks/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setMessage('Ebook uploaded successfully!');
      setFile(null);
      setTitle('');
      setEbookType('');
      setAuthor('');
      setStorageLocation('');
      setCustomFields([]);
      setCustomFieldValues({});
    } catch (error) {
      console.error('Error uploading ebook:', error);
      setMessage('Error uploading ebook. Please try again.');
    }
  };

  const handleDirectorySelect = (directory) => {
    setStorageLocation(directory);
    setShowModal(false);
  };

  const handleCreateDirectory = async () => {
    try {
      await axiosInstance.post('/directories', { parentDirectory: currentDirectory, directoryName: newDirectoryName });
      setNewDirectoryName('');
      fetchDirectoryTree(currentDirectory);
    } catch (error) {
      console.error('Error creating directory:', error);
      setMessage('Error creating directory. Please try again.');
    }
  };

  const handleBack = () => {
    const parentDirectory = currentDirectory.split('/').slice(0, -1).join('/');
    fetchDirectoryTree(parentDirectory);
  };

  const renderDirectoryTree = (nodes) => {
    return nodes.map((node) => (
      node.isDirectory && (
        <ListGroup.Item key={node.path} onClick={() => fetchDirectoryTree(node.path)}>
          {node.name}
        </ListGroup.Item>
      )
    ));
  };

  const autoResizeTextarea = (e) => {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  return (
    <div>
      <h2>Upload Ebook</h2>
      {message && <Alert variant={message.includes('Error') ? 'danger' : 'success'}>{message}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>PDF File</Form.Label>
          <Form.Control type="file" accept=".pdf" onChange={handleFileChange} required />
        </Form.Group>
        <Form.Group>
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter ebook title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Ebook Type</Form.Label>
          <Form.Control
            as="select"
            value={ebookType}
            onChange={(e) => setEbookType(e.target.value)}
            required
          >
            <option value="">Select Ebook Type</option>
            {ebookTypes.map((type) => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Author</Form.Label>
          <Form.Control
            as="select"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            required
          >
            <option value="">Select Author</option>
            {authors.map((auth) => (
              <option key={auth.id} value={auth.id}>
                {auth.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Storage Location</Form.Label>
          <div className="d-flex">
            <Form.Control
              type="text"
              placeholder="Select storage location"
              value={storageLocation}
              readOnly
            />
            <Button variant="secondary" onClick={() => setShowModal(true)}>
              Select Folder
            </Button>
          </div>
        </Form.Group>
        {customFields.map((field) => (
          <Form.Group key={field.id} className="custom-field">
            <Form.Label className="custom-field-label">{field.name}</Form.Label>
            <Form.Control
              as="textarea"
              rows={1}
              value={customFieldValues[field.name] || ''}
              onChange={(e) => handleCustomFieldChange(e, field.name)}
              onInput={autoResizeTextarea}
              required
              className="custom-field-input"
            />
          </Form.Group>
        ))}
        <Button variant="primary" type="submit">
          Upload Ebook
        </Button>
      </Form>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Select Storage Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Create New Folder</Form.Label>
            <div className="d-flex">
              <Form.Control
                type="text"
                placeholder="Enter folder name"
                value={newDirectoryName}
                onChange={(e) => setNewDirectoryName(e.target.value)}
              />
              <Button variant="primary" onClick={handleCreateDirectory}>
                Create
              </Button>
            </div>
          </Form.Group>
          <Button variant="secondary" onClick={handleBack} disabled={!currentDirectory}>
            Back
          </Button>
          <ListGroup>
            {renderDirectoryTree(directoryTree)}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleDirectorySelect(currentDirectory)}>
            Select
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EbookUpload;